import { useEffect, useState } from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import { i18n } from "../../translate/i18n";
import * as Styled from "./style";

export const Header = () => {
  const { width } = useWindowSize();
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    if (width >= 425) {
      setMenu(false);
      return;
    }
  }, [width]);

  return (
    <Styled.Coluna>
      <Styled.Header>
        <nav>
          <input
            type="checkbox"
            name="menu-hamburguer"
            id="menu-hamburguer"
            checked={menu}
            onChange={() => setMenu((prev) => !prev)}
          />

          <label htmlFor="menu-hamburguer">
            <div className="menu">
              <span className="hamburguer"></span>
            </div>
          </label>
          <Styled.Menu>
            <li>
              <Styled.NavMenu href="#Inicio">
                {i18n.t("header.1")}
              </Styled.NavMenu>
            </li>
            <li>
              <Styled.NavMenu href="#Serviços">
                {i18n.t("header.2")}
              </Styled.NavMenu>
            </li>
            <li>
              <Styled.NavMenu href="#Carreira">
                {i18n.t("header.3")}
              </Styled.NavMenu>
            </li>
            <li>
              <Styled.NavMenu href="#Contato">
                {i18n.t("header.4")}
              </Styled.NavMenu>
            </li>
            <li>
              <Styled.NavMenu href="/links">
                {/* {i18n.t("header.4")} */}
                Links e Dicas
              </Styled.NavMenu>
            </li>
          </Styled.Menu>
        </nav>
      </Styled.Header>
    </Styled.Coluna>
  );
};

import styled from "styled-components";

export const Container = styled.div`
  margin-left: 5rem;
  max-width: 27rem;
  text-align: center;
  a {
    display: inline-block;
    margin-top: 2rem;
  }
  @media (max-width: 800px) {
    padding: 0;
    margin-left: 0;
  }
`;

export const PerguntasCard = styled.ul`
  margin-top: 2rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Lista = styled.li`
  width: 100%;
  list-style: none;
  padding: 1rem 0;
  border-bottom: 1px solid #8147f8;
  font-weight: 500;
  :nth-child(1) {
    border-top: 1px solid ${(props) => props.theme.body};
  }
`;

export const Pergunta = styled.summary`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-weight: 500;
  color: ${(props) => props.theme.title};
  :hover {
    color: ${(props) => props.theme.button} !important;
  }
`;

export const Resposta = styled.details`
  padding: 1.5rem 0 0.5rem;
  text-align: left;
  line-height: 1.6;
  color: ${(props) => props.theme.paragraph};
  font-weight: 400;
`;

export const TituloPrincipal = styled.h2`
  text-align: center;
  font-size: 1.8rem;
  color: ${(props) => props.theme.title};
  margin-bottom: 0.7rem;
  @media (max-width: 800px) {
    margin-top: 7rem;
    text-align: center;
  }
`;

export const ParagrafoPrincipal = styled.p`
  margin-top: 1.5rem;
  padding-bottom: 1.6rem;
  text-align: center;
  font-size: 1rem;
  line-height: 1.3;
  color: ${(props) => props.theme.paragraph};
  margin-bottom: 1rem;
`;
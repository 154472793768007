import * as S from "./styled";
import { i18n } from "../../translate/i18n";
import arrow from "./../../assets/icons/icon-arrow.svg";
import React from "react";

export function Perguntas() {
  return (
    <S.Container id="Saiba">
      <S.TituloPrincipal>{i18n.t("questions.titulo")}</S.TituloPrincipal>
      <S.ParagrafoPrincipal>
        {i18n.t("questions.paragrafo")}
      </S.ParagrafoPrincipal>

      <S.PerguntasCard>
        <S.Lista>
          <S.Resposta>
            <S.Pergunta>
              {i18n.t("questions.pergunta1.titulo")}
              <img src={arrow} alt="arrow" />
            </S.Pergunta>
            {i18n.t("questions.pergunta1.resposta")}
          </S.Resposta>
        </S.Lista>

        <S.Lista>
          <S.Resposta>
            <S.Pergunta>
              {i18n.t("questions.pergunta2.titulo")}

              <img src={arrow} alt="arrow" />
            </S.Pergunta>
            {i18n.t("questions.pergunta2.resposta")}
          </S.Resposta>
        </S.Lista>

        <S.Lista>
          <S.Resposta>
            <S.Pergunta>
              {i18n.t("questions.pergunta3.titulo")}

              <img src={arrow} alt="arrow" />
            </S.Pergunta>
            {i18n.t("questions.pergunta3.resposta")}
          </S.Resposta>
        </S.Lista>

        <S.Lista>
          <S.Resposta>
            <S.Pergunta>
              {i18n.t("questions.pergunta4.titulo")}

              <img src={arrow} alt="arrow" />
            </S.Pergunta>
            {i18n.t("questions.pergunta4.resposta")}
          </S.Resposta>
        </S.Lista>
      </S.PerguntasCard>
    </S.Container>
  );
}

import React from "react";
import * as S from "./style";
import { Parcerias } from "../screens/parcerias";
import { Sobre } from "../screens/sobre";
import { Footer } from "../screens/footer";
import { Perguntas } from "../screens/perguntas";
import { Contato } from "../screens/contato";
import { Criacoes } from "../screens/criacoes";
import { Carreira } from "../screens/carreira";
// import { Servicos } from "../screens/servicos";
import { Hobbie } from "../screens/hobbie";
import { Header } from "../screens/header";

export function Layout() {
  return (
    <>
      <Header />
      <section>
        <Sobre />
        {/* <Servicos /> */}
        <Parcerias />
        <Hobbie />
        <Carreira />
        <Criacoes />
        <S.Container2>
          <S.Container3>
            <Contato />
          </S.Container3>
          <Perguntas />
        </S.Container2>
        <Footer />
      </section>
    </>
  );
}

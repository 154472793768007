import styled from "styled-components";

export const Coluna = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-top: 1rem;
  li {
    list-style-type: none;
  }

  @media (max-width: 800px) {
    justify-content: center;
  }
`;

export const Lado = styled.div`
  padding-right: 2rem;
  @media (max-width: 800px) {
    padding: 1rem;
  }
`;

import projetoF from "./image/techFinancas.png";
import copa from "./image/copa.png";
import batman from "./image/batman.png";
import paises from "./image/apiPaises.jpg";
import page from "./image/landinPageMac.jpg";
import sw from "./image/sw.png";
import book from "./image/bookmark.jpg";
import starb from "./image/starb.png";
import noticia from "./image/noticias.png";
import pok from "./image/PO.png";
import wa from "./image/war.png";
import pad from "./image/1.png";
import sp from "./image/2.png";
import kanb from "./image/14.png";
import quiz from "./image/quiz.png";
import desenv from "./imagensMobile/em_desenvolvimento.jpg";

const web = [
  {
    id: "1",
    title: "Starbucks",
    tags: ["web", "REACT", "API", "LOCALSTORAGE", "Typescript"],
    srcImg: starb,
    visit: "https://github.com/techjuliana/Starbucks",
    variant: "web",
  },

  {
    id: "2",
    title: "Calendário da Copa",
    tags: ["web", "REACT", "STYLED COMPONENTS"],
    srcImg: copa,
    visit: "https://github.com/techjuliana/Calendario-Copa",
    variant: "web",
  },
  {
    id: "3",
    title: "Animação do Batman",
    tags: ["web", "CSS", "ANIMATION"],
    description: "Recriando o logo do Batman somente com ",
    srcImg: batman,
    visit: "/",
    variant: "web",
  },
  {
    id: "4",
    title: "Paises pelo Mundo",
    tags: ["web", "API", "SEARCH", "SELECT"],
    srcImg: paises,
    visit: "https://github.com/techjuliana/API-paises",
    variant: "web",
  },
  {
    id: "5",
    title: "Landing Page",
    tags: ["web", "REACT", "STYLED COMPONENTS"],
    srcImg: page,
    visit: "https://github.com/techjuliana/Clipboard-landing-page",
    variant: "web",
  },

  {
    id: "6",
    title: "Aplicação Noticias",
    tags: ["web", "REACT", "STYLED COMPONENTS"],
    srcImg: noticia,
    visit: "https://github.com/techjuliana/Aplicacao-Noticias",
    variant: "web",
  },

  {
    id: "7",
    title: "Aplicação Pokemons",
    tags: ["web", "API", "LOCALSTORAGE"],
    srcImg: pok,
    visit: "https://github.com/techjuliana/Pokemon-reactjs",
    variant: "web",
  },
  {
    id: "8",
    title: "Landing Page bookmark",
    tags: ["web", "REACT", "STYLED COMPONENTS"],
    srcImg: book,
    visit: "https://github.com/techjuliana/Bookmark-landing-page",
    variant: "web",
  },
  {
    id: "9",
    title: "Empresa SW Metálicos",
    tags: ["web", "REACT"],
    srcImg: sw,
    visit: "https://swmetalicos.vercel.app/",
    variant: "web",
  },
  {
    id: "10",
    title: "Tech Finanças",
    tags: ["web", "CRUD", "REACT"],
    srcImg: projetoF,
    visit: "https://github.com/techjuliana/Project-CRUD",
    variant: "web",
  },
  {
    id: "11",
    title: "StarWars Films",
    tags: ["Next.JS", "API", "REACT", "Params"],
    srcImg: wa,
    visit: "https://github.com/techjuliana/starWars",
    variant: "web",
  },
  {
    id: "12",
    title: "Padaria Portuguesa",
    tags: ["SASS", "API", "REDUX", "REACT", "TypeScript"],
    srcImg: pad,
    visit: "https://github.com/techjuliana/store-redux",
    variant: "web",
  },
  {
    id: "13",
    title: "Spotify Page",
    tags: ["Tailwind", "TypeScript", "REACT"],
    srcImg: sp,
    visit: "https://github.com/techjuliana/Spotify-react",
    variant: "web",
  },
  {
    id: "14",
    title: "Kanban",
    tags: ["Lottiefiles", "LocalStorage", "REACT", "Chakra UI"],
    srcImg: kanb,
    visit: "https://github.com/techjuliana/Kanban",
    variant: "web",
  },
  // {
  //   id: "15",
  //   title: "Template administrador",
  //   tags: ["", "TypeScript", "REACT"],
  //   srcImg: sp,
  //   visit: "https://github.com/techjuliana/templateadmin",
  //   variant: "web",
  // },
  {
    id: "16",
    title: "Quiz Tech",
    tags: ["TypeScript", "NEXT.JS"],
    srcImg: quiz,
    visit: "https://github.com/techjuliana/quiztech",
    variant: "web",
  },
  {
    id: "17",
    title: "Projeto em Angular",
    tags: ["Angular"],
    srcImg: desenv,
    visit: "",
    variant: "web",
  },
  {
    id: "18",
    title: "Projeto em VUE",
    tags: ["VUE"],
    srcImg: desenv,
    visit: "",
    variant: "web",
  },
  // {
  //   id: "17",
  //   title: "Social Pets",
  //   tags: ["", "TypeScript", "VUE"],
  //   srcImg: desen,
  //   visit: "https://github.com/techjuliana/socialPets",
  //   variant: "web",
  // },
];

export default web;

import * as S from "./styled";
import perfil from "./techjuliana.jpg";
import React from "react";
import { i18n } from "../../translate/i18n";
import { Redes } from "../redes";
import { ParagrafoLateral, TituloLateral } from "../../theme/padrao";

export function Sobre() {
  return (
    <S.ContainerGeral id="Inicio">
      <S.Imagem2 src={perfil} alt="Logo" />
      <S.Lado>
        <TituloLateral>
          {i18n.t("sobre.titulo")}
          <span> &#128075;</span>
        </TituloLateral>

        <ParagrafoLateral>
          {i18n.t("sobre.paragrafo1")}
          <strong>{i18n.t("sobre.paragrafo2")}</strong>
          {i18n.t("sobre.paragrafo3")}
        </ParagrafoLateral>
        <Redes />
      </S.Lado>
    </S.ContainerGeral>
  );
}

import {
  BrowserRouter,
  Route,
  Routes as BrowserRoutes
} from "react-router-dom";
import React from "react";
import { Layout } from "../Layout";
import { Projetos } from "../screens/projetos";
import { Error } from "../screens/error";
import { Links } from "../screens/Links";

export function Routes() {
  return (
    <BrowserRouter>
      <BrowserRoutes>
        <Route path="/" element=<Layout /> />
        <Route path="/projetos" element=<Projetos /> />
        <Route path="/links" element=<Links /> />
        <Route path="*" element=<Error /> />
      </BrowserRoutes>
    </BrowserRouter>
  );
}

import styled from "styled-components";

export const ContainerPrincipal = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const Informacao = styled.a`
  padding: 0.6rem 6rem;
  background: ${(props) => props.theme.button};
  border: 2px solid ${(props) => props.theme.button};
  border-radius: 0.3rem;
  box-shadow: 0 0 5px #8147f8;
  -webkit-box-shadow: 0 0 10px #8147f8;
  font-size: 0.9rem;
  font-weight: 500;
  color: ${(props) => props.theme.title};
  @media (max-width: 800px) {
    padding: 0.6rem 3rem;
  }
`;

export const Soft = styled.h6`
  font-size: 0.9rem;
  color: ${(props) => props.theme.title};
  font-weight: 500;
`;

export const Coluna = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Centro = styled.div`
  background-color: #b090f9;
  margin: 0.2rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-radius: 1rem;
`;

export const Container = styled.div`
  padding: 4rem;
  width: 30rem;
  text-align: start;
  a {
    display: inline-block;
    margin-top: 2rem;
  }
  @media (max-width: 800px) {
    width: 80%;
    padding: 0;
  }
`;

export const PerguntasCard = styled.ul`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const Lista = styled.li`
  width: 100%;
  list-style: none;
  padding: 1rem 0;
  border-bottom: 1px solid #8147f8;
  font-weight: 500;
  :nth-child(1) {
    border-top: 1px solid ${(props) => props.theme.body};
  }
`;

export const Pergunta = styled.h4`
  display: flex;
  font-weight: 500;
  color: ${(props) => props.theme.title};
`;

export const Resposta = styled.h4`
  line-height: 1.6;
  color: ${(props) => props.theme.paragraph};
  font-weight: 400;
`;

export const TituloPrincipal = styled.h2`
  text-align: start;
  font-size: 1.8rem;
  color: ${(props) => props.theme.title};
  margin-bottom: 0.9rem;
  @media (max-width: 800px) {
    margin-top: 2rem;
  }
`;
import styled from "styled-components";
// adicionar aqui os estilos que se repetem como botoes etc

// Titulo
export const TituloLateral = styled.h2`
  font-size: 1.4rem;
  margin-bottom: 1.3rem;
  color: ${(props) => props.theme.title};
`;

export const TituloCentral = styled.h2`
  font-size: 1.4rem;
  text-align: center;
  color: ${(props) => props.theme.title};
`;

// Paragrafo
export const ParagrafoCentral = styled.p`
  max-width: 35rem;
  font-size: 1rem;
  text-align: center;
  margin: 1rem;
  color: ${(props) => props.theme.paragraph};
`;

// Botao

// Texto Lateral
export const ParagrafoLateral = styled.p`
  max-width: 26rem;
  font-size: 1rem;
  color: ${(props) => props.theme.paragraph};
`;

import um from "./imagensMobile/1.png";
import dois from "./imagensMobile/2.png";
import tres from "./imagensMobile/3.png";
import quatro from "./imagensMobile/4.png";
import cinco from "./imagensMobile/5.png";
import seis from "./imagensMobile/6.png";
import sete from "./imagensMobile/7.png";
import desenv from "./imagensMobile/em_desenvolvimento.jpg";

const mobile = [
  {
    id: "1",
    title: "Aplicativo de Filmes",
    tags: ["Modal", "Onboarding", "API", "Loading"],
    srcImg: um,
    visit: "https://github.com/techjuliana/app-movies",
    variant: "mobile",
  },
  {
    id: "2",
    title: "Aplicativo EGames",
    tags: ["Picker", "Slider", "Switch", "Modal", "Onboarding"],
    srcImg: dois,
    visit: "https://github.com/techjuliana/eGames",
    variant: "mobile",
  },
  {
    id: "3",
    title: "Hamburgueria",
    tags: ["Camera", "Drawer", "Carousel", "Onboarding"],
    srcImg: tres,
    visit: "https://github.com/techjuliana/App-Store",
    variant: "mobile",
  },
  {
    id: "4",
    title: "Aplicativo Pokedex",
    tags: ["api", "Loading", "Typescript", "params"],
    srcImg: quatro,
    visit: "https://github.com/techjuliana/App-Pokedex",
    variant: "mobile",
  },
  {
    id: "5",
    title: "Aplicativo GitHub",
    tags: ["api", "Tags", "Typescript", "params", "Firebase"],
    srcImg: cinco,
    visit: "https://github.com/techjuliana/AppHub",
    variant: "mobile",
  },
  {
    id: "6",
    title: "Aplicativo Tarefas",
    tags: ["Firebase", "AdMob", "LocalStorage", "Typescript"],
    srcImg: seis,
    visit: "https://play.google.com/store/apps/dev?id=8643197536908223173",
    variant: "mobile",
  },
  {
    id: "7",
    title: "Receitas da Vovó",
    tags: ["NodeJS", "Express", "LocalStorage", "React Native"],
    srcImg: sete,
    visit: "https://play.google.com/store/apps/details?id=com.appreceitas01",
    variant: "mobile",
  },
  {
    id: "8",
    title: "Reclame Cidade",
    tags: ["ReactNative", "NodeJS", "MongoDB", "Google Cloud", "Firebase"],
    srcImg: desenv,
    visit: "",
    variant: "mobile",
  },
  {
    id: "9",
    title: "Inspirações Criativas",
    tags: ["ReactNative", "NodeJS", "MongoDB", "Google Cloud", "Firebase"],
    srcImg: desenv,
    visit: "",
    variant: "mobile",
  },
  // {
  //   id: "8",
  //   title: "",
  //   tags: ["Firebase", "AdMob", "LocalStorage", "Typescript"],
  // srcImg: seis,
  //   visit: "",
  //   variant: "mobile",
  // },
];

export default mobile;

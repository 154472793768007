import styled from "styled-components";

export const Container = styled.div`
  z-index: 999;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a {
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 700px) {
    margin-top: 4rem;
  }
`;

export const ContainerShowcase = styled.section`
  display: grid;
  padding-inline: 16px;
  .showcase__button {
    width: fit-content;
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    margin-top: 40px;
    .showcase__button {
      margin: 40px auto 0;
      grid-column: 1 / span 1;
    }
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 60px;
    column-gap: 20px;
    .showcase__button {
      grid-column: 1 / span 2;
      margin: 32px auto 0;
    }
  }
  @media screen and (min-width: 1025px) {
    grid-template-columns: repeat(3, 1fr);
    max-width: 50rem;
    justify-content: center;
    margin-inline: auto;
    margin-top: 20px;
    column-gap: 0px;
    .showcase__button {
      grid-column: 1 / span 4;
      margin: 32px auto 0;
    }
  }
`;

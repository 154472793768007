import { ThemeProvider } from "styled-components";
import GlobalStyle from "../theme/global";
import { Routes } from "../routes";
import { lightTheme, darkTheme } from "../theme/theme";
import React, { useState } from "react";
import { Botao } from "../screens/header/style";

export function App() {
  const [theme, setTheme] = useState("dark");

  const themeToggler = () => {
    theme === "dark" ? setTheme("light") : setTheme("dark");
  };

  const clicks = () => {
    themeToggler();
  };

  return (
    <div>
      <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
        <GlobalStyle />
        <Botao onClick={() => clicks()}>{theme === "dark" ? "☀️" : "🌙"}</Botao>
        <Routes />
      </ThemeProvider>
    </div>
  );
}

import styled from "styled-components";

export const Imagem = styled.img`
  max-width: 17rem;
  border-radius: 1rem;
`;

export const Imagem2 = styled.img`
  max-width: 17rem;
  border-radius: 2rem;
`;

export const ContainerGeral = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  padding-top: 10rem;
  padding-bottom: 3rem;
  color: ${(props) => props.theme.title};
  @media (max-width: 800px) {
    text-align: center;
    flex-direction: column;
  }
  img {
    box-shadow: 0 0 10px #8147f8;
    -webkit-box-shadow: 0 0 10px #8147f8;
  }
  h5 {
    margin-top: 2rem;
  }
`;

// export const Titulo = styled.h2`
//   font-size: 1.4rem;
//   margin-bottom: 1.3rem;
//   color: ${(props) => props.theme.title};
// `;

export const Lado = styled.div`
  padding: 1rem;
`;

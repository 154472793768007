import React from "react";
import * as S from "./styled";
import { i18n } from "../../translate/i18n";
import curriculo from "./../../assets/pdf/juliana.pdf";

export function Carreira() {
  const SoftFront = [
    "React Native",
"React",
"Analytics",
"Redux",
"Context API",
"Next",
"JavaScript",
"TypeScript",
"SASS",
"Tailwind",
"Responsive",
"APIs",
"Styled Components",
"translate nos textos / i18n",
"Jest e Coverage",
"SandBox",
"AdMob",
  ];
  const Softback = ["FireBase", "Strapi", "Node", "Criação de APIs",  "MongoDB", "Google Cloud",];
  const Study = ["Flutter", "Node"];
  const Ferramentas = ["ARGO | Versão em Sandbox para os QA's testarem tanto APP e Web ",
    "Datadog | observabilidade",
    "Firebase Analytics | observabilidade",
    "Processo de releases",
    "VTEX | API",
    "VTEX CMS",
    "Amplitude",
    "Gitlab",];

  return (
    <S.ContainerPrincipal id="Carreira">
      <S.Container>
        <S.PerguntasCard>
          <S.Lista>
            <S.TituloPrincipal>
              {i18n.t("educacao.titulo")} <span>&#128218;</span>
            </S.TituloPrincipal>
            <S.Resposta>
              <S.Pergunta>{i18n.t("educacao.info1.titulo")}</S.Pergunta>
              {i18n.t("educacao.info1.paragrafo")}
            </S.Resposta>
          </S.Lista>

          <S.Lista>
            <S.Resposta>
              <S.Pergunta> {i18n.t("educacao.info2.titulo")}</S.Pergunta>
              {i18n.t("educacao.info2.paragrafo")}
            </S.Resposta>
          </S.Lista>

          <S.Lista>
            <S.Resposta>
              <S.Pergunta>{i18n.t("educacao.back")}</S.Pergunta>
              <S.Coluna>
                {Softback.map((parceria, index) => (
                  <S.Centro key={index}>
                    <S.Soft>{parceria}</S.Soft>
                  </S.Centro>
                ))}
              </S.Coluna>
            </S.Resposta>
          </S.Lista>

          <S.Lista>
            <S.Resposta>
              <S.Pergunta>{i18n.t("educacao.front")}</S.Pergunta>
              <S.Coluna>
                {SoftFront.map((parceria, index) => (
                  <S.Centro key={index}>
                    <S.Soft>{parceria}</S.Soft>
                  </S.Centro>
                ))}
              </S.Coluna>
            </S.Resposta>
          </S.Lista>
          {/* estudando */}
          <S.Lista>
            <S.Resposta>
              <S.Pergunta>Ferramentas que tenho conhecimento</S.Pergunta>
              <S.Coluna>
                {Ferramentas.map((ferramentas , index) => (
                  <S.Centro key={index}>
                    <S.Soft>{ferramentas}</S.Soft>
                  </S.Centro>
                ))}
              </S.Coluna>
            </S.Resposta>
          </S.Lista>

          <S.Lista>
            <S.Resposta>
              <S.Pergunta>{i18n.t("educacao.study")}</S.Pergunta>
              <S.Coluna>
                {Study.map((Study , index) => (
                  <S.Centro key={index}>
                    <S.Soft>{Study}</S.Soft>
                  </S.Centro>
                ))}
              </S.Coluna>
            </S.Resposta>
          </S.Lista>
        </S.PerguntasCard>
      </S.Container>
      <S.Container>
        <S.PerguntasCard>
          <S.Lista>
            <S.TituloPrincipal>
              {i18n.t("experiencia.titulo")}
              <span> &#128188;</span>
            </S.TituloPrincipal>
            <S.Resposta>
              <S.Pergunta>{i18n.t("experiencia.info1.titulo")}</S.Pergunta>
              {i18n.t("experiencia.info1.paragrafo")}
            </S.Resposta>
          </S.Lista>

          <S.Lista>
            <S.Resposta>
              <S.Pergunta>{i18n.t("experiencia.info2.titulo")}</S.Pergunta>
              {i18n.t("experiencia.info2.paragrafo")}
            </S.Resposta>
          </S.Lista>

          <S.Lista>
            <S.Resposta>
              <S.Pergunta>{i18n.t("experiencia.info3.titulo")}</S.Pergunta>
              {i18n.t("experiencia.info3.paragrafo")}
            </S.Resposta>
          </S.Lista>
          <S.Lista>
            <S.Resposta>
              <S.Pergunta>{i18n.t("experiencia.info4.titulo")}</S.Pergunta>
              <S.Informacao href={curriculo}>
                {i18n.t("experiencia.info4.paragrafo")}
              </S.Informacao>
            </S.Resposta>
          </S.Lista>
        </S.PerguntasCard>
      </S.Container>
    </S.ContainerPrincipal>
  );
}

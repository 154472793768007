import React from "react";
import { Top } from "./partials/Top";
import { useShowcase } from "./useShowcase";
import { ContainerShowcase, Container } from "./style";
import mobile from "./data/mobile";
import web from "./data/web";
import * as S from "./styledCard/styled";
import { i18n } from "../../translate/i18n";
import { HeaderComponentExterno } from "../HeaderComponentExterno";

export function Projetos() {
  const {
    isDisabledButtonShowcase,
    isDisabledButtonList,
    currenTab,
    onChangeTab,
  } = useShowcase();

  const dataMobile = [...mobile].reverse();
  const dataWeb = [...web].reverse();

  return (
    <>
      <HeaderComponentExterno />
      <Container>
        <Top
          isDisabledButtonShowcase={isDisabledButtonShowcase}
          isDisabledButtonList={isDisabledButtonList}
          currenTab={currenTab}
          onChangeTab={onChangeTab}
        />
        <ContainerShowcase>
          {currenTab === "showcase" &&
            dataMobile.map((item) => (
              <S.Card key={item.id}>
                <S.ImagemProjeto src={item.srcImg} alt="" />
                <S.CardText>
                  <h2>{item.title}</h2>
                  <S.Tag>
                    {item.tags?.map((tag) => (
                      <h6 key={tag}>{tag}</h6>
                    ))}
                  </S.Tag>
                </S.CardText>
                <S.ContainerBtn>
                  <a href={item.visit} target="_blank" rel="noreferrer">
                    <S.BotaoGit> {i18n.t("projetos.botao")}</S.BotaoGit>
                  </a>
                </S.ContainerBtn>
              </S.Card>
            ))}
          {currenTab === "list" &&
            dataWeb.map((item) => (
              <S.Card key={item.id}>
                <S.ImagemProjeto src={item.srcImg} alt="" />
                <S.CardText>
                  <h2>{item.title}</h2>
                  <S.Tag>
                    {item.tags?.map((tag) => (
                      <h6 key={tag}>{tag}</h6>
                    ))}
                  </S.Tag>
                </S.CardText>
                <S.ContainerBtn>
                  <a href={item.visit} target="_blank" rel="noreferrer">
                    <S.BotaoGit>{i18n.t("projetos.botao")}</S.BotaoGit>
                  </a>
                </S.ContainerBtn>
              </S.Card>
            ))}
        </ContainerShowcase>
      </Container>
    </>
  );
}

import styled from "styled-components";

export const Container2 = styled.div`
  padding: 1rem;
  justify-content: center;
  display: flex;
  flex-direction: row;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const Container3 = styled.div`
  padding: 2rem;
  margin-top: 1rem;
  border-radius: 1rem 7rem 1rem 7rem;
  background-color: ${(props) => props.theme.header};
  /* background-color: ${(props) => props.theme.cards}; */
  @media (max-width: 800px) {
    width: 100%;
    justify-content: center;
  }
`;

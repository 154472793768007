import * as S from "./styled";
import { i18n } from "../../translate/i18n";
import React from "react";
import { ParagrafoLateral, TituloLateral } from "../../theme/padrao";

export function Contato() {
  return (
    <S.Container id="Contato">
      <TituloLateral>{i18n.t("contato.titulo")}</TituloLateral>
      <ParagrafoLateral>{i18n.t("contato.paragrafo")}</ParagrafoLateral>

      <S.Informacao href="https://www.linkedin.com/in/techjuliana">
        LinkedIn
      </S.Informacao>
    </S.Container>
  );
}

import styled, { css } from "styled-components";

export const ContainerTop = styled.section`
  ${() => css`
    max-width: 58rem;
    padding-inline: 16px;
    column-gap: 16px;
    text-align: center;
    justify-content: center;
    .showcase__switch {
      flex-direction: row;
    }
    .switch__button--left,
    .switch__button--right {
      padding: 0.6rem 2rem;
      background: ${(props) => props.theme.button};
      border: 2px solid ${(props) => props.theme.button};
      border-radius: 0.3rem;
      box-shadow: 0 0 5px #8147f8;
      -webkit-box-shadow: 0 0 10px #8147f8;
      font-size: 0.9rem;
      font-weight: 500;
      margin: 5px;
    }
    @media screen and (max-width: 760px) {
      margin: 60px auto 0;
      .header__text {
        .header__title {
          font-size: 10px;
          word-break: break-all;
        }
        .header__description {
          margin-top: 8px;
          word-break: break-all;
        }
      }
      .showcase__switch {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
      .switch__button--left,
      .switch__button--right {
        padding: 0px;
      }
    }
    @media screen and (min-width: 761px) {
      margin: 180px auto 0;

      .header__text {
        .header__title {
          font-size: 10px;
        }
        .header__description {
          margin-top: 36px;
        }
      }
      .showcase__switch {
        column-gap: 40px;
        .switch__button--left,
        .switch__button--right {
          padding: 0.6rem 6rem;
          background: ${(props) => props.theme.button};
          border: 2px solid ${(props) => props.theme.button};
          border-radius: 0.3rem;
          box-shadow: 0 0 5px #8147f8;
          -webkit-box-shadow: 0 0 10px #8147f8;
          font-size: 0.9rem;
          font-weight: 500;
        }
      }
    }
  `}
`;

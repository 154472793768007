import React from "react";
import { ContainerTop } from "./style";

export function Top({
  isDisabledButtonShowcase,
  isDisabledButtonList,
  onChangeTab,
}) {
  return (
    <ContainerTop>
      <div className="showcase__switch">
        <button
          className="switch__button switch__button--left"
          disabled={isDisabledButtonShowcase}
          onClick={() => {
            onChangeTab("showcase");
          }}
        >
          Mobile
        </button>
        <button
          className="switch__button switch__button--right"
          disabled={isDisabledButtonList}
          onClick={() => {
            onChangeTab("list");
          }}
        >
          Web
        </button>
      </div>
    </ContainerTop>
  );
}

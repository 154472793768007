import { useEffect, useState } from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
// import { i18n } from "../../translate/i18n";
import * as Styled from "./style";
import { Coluna } from "../header/style";

export const HeaderComponentExterno = () => {
  const { width } = useWindowSize();
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    if (width >= 425) {
      setMenu(false);
      return;
    }
  }, [width]);

  return (
    <Coluna>
      <Styled.Header>
        <nav>
          <input
            type="checkbox"
            name="menu-hamburguer"
            id="menu-hamburguer"
            checked={menu}
            onChange={() => setMenu((prev) => !prev)}
          />
          <label htmlFor="menu-hamburguer">
            <div className="menu">
              <span className="hamburguer"></span>
            </div>
          </label>
          <Styled.Menu>
            <li href="/">
              <Styled.NavMenu href="/">Ir para o Inicio</Styled.NavMenu>
            </li>
          </Styled.Menu>
        </nav>
      </Styled.Header>
    </Coluna>
  );
};

import * as S from "./styled";
import { i18n } from "../../translate/i18n";
import React from "react";
import animation from "./animation/Animation3.gif";
import { ParagrafoCentral, TituloCentral } from "../../theme/padrao";

export function Criacoes() {
  return (
    <S.Container>
      <TituloCentral>{i18n.t("criacoes.titulo")}</TituloCentral>
      <ParagrafoCentral>{i18n.t("criacoes.paragrafo")}</ParagrafoCentral>
      <img src={animation} alt="mac" />
      <S.Informacao href="/projetos">{i18n.t("criacoes.botao")}</S.Informacao>
    </S.Container>
  );
}

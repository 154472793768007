import React from "react";
import { Coluna, Lado } from "./style";
import { IconContext } from "react-icons";
import { BsGithub, BsLinkedin } from "react-icons/bs";
import { GrInstagram, GrYoutube } from "react-icons/gr";
import { SiTiktok } from "react-icons/si";

export function Redes() {
  return (
    <IconContext.Provider value={{ color: "#9a6df9", size: "1.7em" }}>
      <Coluna>
        <li>
          <a
            href="https://github.com/techjuliana"
            target="_blank"
            rel="noreferrer"
          >
            <Lado>
              <BsGithub />
            </Lado>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/tech.juliana/?utm_medium=copy_link"
            target="_blank"
            rel="noreferrer"
          >
            <Lado>
              <GrInstagram />
            </Lado>
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/techjuliana/"
            target="_blank"
            rel="noreferrer"
          >
            <Lado>
              <BsLinkedin />
            </Lado>
          </a>
        </li>
        <li>
          <a
            href="https://www.tiktok.com/@techjuliana"
            target="_blank"
            rel="noreferrer"
          >
            <Lado>
              <SiTiktok />
            </Lado>
          </a>
        </li>
        <li>
          <a
            href="https://youtu.be/yo9W-R0bA5Q?si=5deXWbkHLH8FpOkf&list=UULFb912uf3zcQO8eDl34HpLNQ"
            target="_blank"
            rel="noreferrer"
          >
            <Lado>
              <GrYoutube />
            </Lado>
          </a>
        </li>
      </Coluna>
    </IconContext.Provider>
  );
}

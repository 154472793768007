const StringsComuns = {
  pt: {
    components: {
      sobre: {
        titulo: "Olá, sou a Tech Juliana",
        paragrafo1: "Sou desenvolvedora de",
        paragrafo2:
          " aplicaçōes web, mobile e de criação de conteúdo de programação,",
        paragrafo3:
          " ensinando várias dicas em redes sociais para a galera que deseja se aventurar nesse universo vasto de tecnologia.",
      },
      hobbie: {
        titulo: "@TechJuliana - Hobbies e Vida",
        paragrafo1:
          "Além de dev e criadora de conteúdo no @TechJuliana, nos meus momentos de lazer, gosto muito de me aventurar nos passeios, ir para lugares diferentes, históricos e com uma musica boa. Adoro ler e ter um tempo sozinha, chamo isso de solitude, é muito bom enquanto ouve uma música.",
        paragrafo2:
          "As músicas que mais curto são: The Neighborhood, Lana Dey Rey, BTS, Bring Me The Horizon e Chase Atlantic",
        paragrafo3:
          "Gosto também de desenhar no meu sketchbook e escrever meus próprios livros de ficção, apesar de não ser muito boa, mas é algo que me faz bem, e me ajuda a relaxar. Ainda não cheguei a publicar nenhum, mas quem sabe um dia...",
      },
      header: {
        1: "Inicio",
        2: "Serviços e Parcerias",
        3: "Carreira",
        4: "Perguntas e Contato",
      },
      projetos: {
        botao: "Visitar",
      },
      questions: {
        titulo: "Perguntas frequentes",
        paragrafo:
          "Aqui estão algumas das nossas perguntas frequentes. Se você tiver outra pergunta, sinta-se à vontade para enviar mensagem no Linkedln.",
        pergunta1: {
          titulo: "Quem é Tech Juliana?",
          resposta:
            "@TechJuliana é desenvolvedora de aplicaçōes web, mobile e de criação de conteúdo de programação, ensinando e compartilhando várias dicas em redes sociais para quem deseja se aventurar nesse universo da tecnologia.",
        },
        pergunta2: {
          titulo: "Oferece mentoria?",
          resposta:
            "No momento infelizmente @TechJuliana não oferece mentorias e tutorias particulares, mas compartilha dicas nas redes sociais",
        },
        pergunta3: {
          titulo: "Está disponível para parcerias nas redes sociais?",
          resposta:
            "Sim, @TechJuliana tem disponibilidade para participar de eventos presenciais, recebidos relacionados a estudo e programação, divulgação de cursos, faculdades e plataformas",
        },
        pergunta4: {
          titulo: "Como entrar em contato @TechJuliana?",
          resposta: "Por mensagem no LinkedIn ou Instagram",
        },
      },
      contato: {
        titulo:
          "Você tem alguma idéia de projeto, ou interesse em trabalhar juntos?",
        paragrafo: "Me envie mensagem pelo Linkedln, para conversarmos",
      },
      parceria: {
        titulo: "Parcerias",
        mais: "E muito mais... :)",
        paragrafo:
          "Eventos presenciais, recebidos relacionados a estudo e programação, divulgação de cursos, faculdades e plataformas",
      },
      experiencia: {
        titulo: "Experiência",
        info1: {
          titulo: "GO.K",
          paragrafo:
            "Trabalhando como frontend web e mobile react native. Presente na empresa RD, ( DrogaRaia e Drogasil ), e projetos internos da empresa GO.K ",
        },
        info2: {
          titulo: "EBAC",
          paragrafo:
            "Tutoria de programação nos cursos de frontend, apoiando alunos em sua jornada profissional.",
        },
        info3: {
          titulo: "Fundadora e Criadora de Conteúdo",
          paragrafo:
            "Criadora de vários conteúdos na área de tecnologia e programação nas redes @TechJuliana: • Instagram • YouTube • Linkedln • TikTok",
        },
        info4: {
          titulo: "Currículo",
          paragrafo: "Arquivo em PDF",
        },
      },
      educacao: {
        titulo: "Educação",
        back: "Conhecimento Backend",
        front: "Conhecimento Frontend",
        study: "Estudando",
        info1: {
          titulo: "Presbiteriana Mackenzie",
          paragrafo: "Analise e Desenvolvimento de Sistemas",
        },
        info2: {
          titulo:
            "Origamid, Rocketseat, Sujeito Programador, Alura, Mergo, Domestika, Udemy e outros",
          paragrafo:
            "Diversos cursos de desenvolvimento web e mobile para aperfeiçoamento e Habilidades interpessoais (Soft e Hard Skills). Com o objetivo de aperfeiçoar a jornada dev",
        },
      },
      criacoes: {
        titulo: "Projetos desenvolvidos",
        paragrafo: "Aqui estão alguns projetos desenvolvidos em web e mobile",
        botao: "Galeria de Projetos",
      },
      aplicativo: {
        titulo: "Idéias que ganharam vida",
      },
      error: {
        titulo: "Olá, você caiu na famosa página de erro :P",
        paragrafo:
          "Isso significa que... houve um erro! Ou a gente não conseguiu carregar sua página, ou você digitou o endereço errado. Acontece, né? Então, por favor: digite de novo, ou volte um pouquinho mais tarde.",
        botao: "Voltar",
      },
      servicos: {
        titulo: "Mídia Kit",
        paragrafo: "Serviços em que posso te ajudar e auxiliar",
        servico1: "Palestras",
        servico2: "Divulgações nas Redes",
        servico3: "Eventos",
      },
    },
  },
};
export { StringsComuns }
import { Redes } from "../redes";
import { Container } from "./style";
import React from "react";

export function Footer() {
  return (
    <Container>
      <footer className="footer">
        <div className="container">
          <section>
            <Redes />
          </section>
          <section>
            <p>
              Copyright © 2024
              <a
                href="https://techjuliana.com.br/"
                target="_blank"
                rel="noreferrer"
              >
                techjuliana.com.br
              </a>
            </p>
          </section>
        </div>
      </footer>
    </Container>
  );
}

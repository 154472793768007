import React from "react";

export function useShowcase() {
  const [currenTab, setCurrenTab] = React.useState("showcase");
  const isShowcase = currenTab === "showcase";
  const isList = currenTab === "list";

  function onChangeTab(value){
    setCurrenTab(value);
  }

  return {
    onChangeTab,
    isDisabledButtonShowcase: isShowcase,
    isDisabledButtonList: isList,
    currenTab
  };
}

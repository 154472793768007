import * as S from "./styled";
import perfil from "./../../assets/images/me4.jpeg";
import React from "react";
import { i18n } from "../../translate/i18n";
import { ParagrafoLateral, TituloLateral } from "../../theme/padrao";

export function Hobbie() {
  return (
    <S.ContainerGeral>
      <S.Lado>
        <TituloLateral>
          {i18n.t("hobbie.titulo")}
          <span> &#127890;</span>
        </TituloLateral>

        <ParagrafoLateral>{i18n.t("hobbie.paragrafo1")}</ParagrafoLateral>
        <br />
        <ParagrafoLateral>{i18n.t("hobbie.paragrafo2")}</ParagrafoLateral>
        <br />
        <ParagrafoLateral>{i18n.t("hobbie.paragrafo3")}</ParagrafoLateral>
      </S.Lado>
      <S.Imagem src={perfil} alt="Logo" />
    </S.ContainerGeral>
  );
}

import styled from "styled-components";

export const Container = styled.div`
  .footer {
    width: 100%;
    height: auto;
    background-color: ${(props) => props.theme.header};
    margin-top: 1.5rem;
    padding-bottom: 1rem;
    /* box-shadow: 0 0 10px #8147f8;
    -webkit-box-shadow: 0 0 10px #8147f8; */
    p {
      color: ${(props) => props.theme.title};
    }
  }

  .footer section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .footer div {
    display: flex;
    align-items: center;
  }

  .footer p {
    font-size: 1.3rem;
    line-height: 160%;
  }

  .footer a {
    font-weight: 500;
    padding: 0px 8px;
    color: #8147f8;
  }

  .footer img {
    width: 32px;
  }

  @media screen and (max-width: 1140px) {
    .footer section {
      text-align: center;
      justify-content: center;
    }

    .footer div:nth-child(2) {
      display: none;
    }
  }
  @media screen and (max-width: 640px) {
    .container {
      display: flex;
      flex-direction: column;
    }
  }
`;

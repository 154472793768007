import styled from "styled-components";

export const Container = styled.div`
  padding: 4rem 0rem;
  justify-content: center;
  max-width: 20rem;
  text-align: start;

  a {
    display: inline-block;
    margin-top: 2rem;
    color: ${(props) => props.theme.title};
  }
  @media (max-width: 600px) {
    text-align: center;
    margin: 0;
    padding: 0;
  }
`;

export const Informacao = styled.a`
  padding: 0.6rem 1.2rem;
  background: ${(props) => props.theme.button};
  border: 2px solid ${(props) => props.theme.button};
  box-shadow: 0 0 5px #8147f8;
  -webkit-box-shadow: 0 0 10px #8147f8;
  border-radius: 0.3rem;
  font-size: 0.9rem;
  font-weight: 500;
  :hover {
    color: ${(props) => props.theme.button};
  }
`;
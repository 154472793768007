import styled from "styled-components";

export const ContainerGeral = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  color: ${(props) => props.theme.title};
  @media (max-width: 800px) {
    text-align: center;
    flex-direction: column;
  }
  img {
    max-width: 17rem;
  }
  h5 {
    margin-top: 2rem;
  }
`;

export const Titulo = styled.h2`
  font-size: 1.4rem;
  margin-bottom: 1.3rem;
`;

export const Paragrafo = styled.p`
  max-width: 30rem;
  font-size: 1rem;
  padding-bottom: 2rem;
`;

export const Lado = styled.div`
  padding: 2rem;
  @media (max-width: 800px) {
    padding: 0rem;
    margin: 1rem;
  }
`;

export const Informacao = styled.a`
  padding: 0.6rem 12rem;
  background: ${(props) => props.theme.button};
  border: 2px solid ${(props) => props.theme.button};
  border-radius: 0.3rem;
  box-shadow: 0 0 5px #8147f8;
  -webkit-box-shadow: 0 0 10px #8147f8;
  font-size: 0.9rem;
  font-weight: 500;
  color: ${(props) => props.theme.title};
  @media (max-width: 800px) {
    padding: 0.6rem 3rem;
  }
`;

import * as S from "./styled";
import { i18n } from "../../translate/i18n";
import Animation1 from "./../servicos/animation/Animation1.gif";
import React from "react";

export function Error() {
  return (
    <S.ContainerGeral>
      <img src={Animation1} alt="dev" />
      <S.Lado>
        <S.Titulo>{i18n.t("error.titulo")}</S.Titulo>
        <S.Paragrafo>{i18n.t("error.paragrafo")}</S.Paragrafo>
        <S.Informacao href="/">{i18n.t("error.botao")}</S.Informacao>
      </S.Lado>
    </S.ContainerGeral>
  );
}

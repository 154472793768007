const StringsComuns = {
  en: {
    components: {
      sobre: {
        titulo: "Hello, I'm Tech Juliana",
        paragrafo1: "I'm a developer",
        paragrafo2:
          " web, mobile and programming content creation applications,",
        paragrafo3:
          " teaching various tips on social networks to people who want to venture into this vast universe of technology.",
      },
      hobbie: {
        titulo: "@TechJuliana - Hobbies and Life",
        paragrafo1:
          "In addition to being a dev and content creator at @TechJuliana, in my leisure time, I really enjoy going on adventures, going to different, historical places with good music. I love reading and having time alone, I call it solitude, it's great while listening to music.",
        paragrafo2:
          "The songs I enjoy the most are: The Neighborhood, Lana Dey Rey, BTS, Bring Me The Horizon e Chase Atlantic",
        paragrafo3:
          "I also like drawing in my sketchbook and writing my own fiction books, although I'm not very good, but it's something that makes me feel good and helps me relax. I haven't published any yet, but maybe one day...",
      },
      header: {
        1: "Start",
        2: "Services and Partnerships",
        3: "Career",
        4: "Questions and Contact",
      },
      projetos: {
        botao: "Visit",
      },
      questions: {
        titulo: "Common questions",
        paragrafo:
          "Here are some of our frequently asked questions. If you have any other questions you would like answered, feel free to post them on Linkedln.",
        pergunta1: {
          titulo: "Who is Tech Juliana",
          resposta:
            "@TechJuliana is a developer of web and mobile applications and the creation of programming content, teaching and sharing various tips on social networks for those who want to venture into this world of technology.",
        },
        pergunta2: {
          titulo: "Do you offer mentoring?",
          resposta:
            "At the moment, unfortunately @TechJuliana does not offer private mentoring and tutoring, but shares tips on social media",
        },
        pergunta3: {
          titulo: "Are you available for partnerships on social media?",
          resposta:
            "Yes, @TechJuliana is available to participate in in-person events, received related to study and programming, promotion of courses, faculties and platforms",
        },
        pergunta4: {
          titulo: "How to contact @TechJuliana?",
          resposta: "By message on LinkedIn or Instagram",
        },
      },
      contato: {
        titulo:
          "Do you have any project ideas, or interest in working together?",
        paragrafo: "Send me a message on Linkedln so we can talk",
      },
      parceria: {
        titulo: "Partnerships",
        mais: "And much more... :)",
        paragrafo:
          "In-person events received related to study and programming, promotion of courses, faculties and platforms",
      },
      experiencia: {
        titulo: "Experience",
        info1: {
          titulo: "GO.K",
          paragrafo:
            "Working as web frontend and mobile react native. Present at the company RD, (DrogaRaia and Drogasil), and internal projects at the company GO.K",
        },
        info2: {
          titulo: "EBAC",
          paragrafo:
            "Programming tutoring in frontend courses, supporting students on their professional journey.",
        },
        info3: {
          titulo: "Founder and Content Creator",
          paragrafo:
            "Creator of various content in the area of ​​technology and programming on @TechJuliana networks: • Instagram • YouTube • Linkedln • TikTok",
        },
        info4: {
          titulo: "Resume",
          paragrafo: "PDF file",
        },
      },
      educacao: {
        titulo: "Education",
        back: "Backend Knowledge",
        front: "Frontend Knowledge",
        study: "Studying",
        info1: {
          titulo: "Presbiteriana Mackenzie",
          paragrafo: "Analysis and systems development",
        },
        info2: {
          titulo:
            "Origamid, Rocketseat, Sujeito Programador, Alura, Mergo, Domestika, Udemy e outros",
          paragrafo:
            "Diverse web and mobile development courses to improve interpersonal skills (Soft and Hard Skills). With the aim of improving the dev journey",
        },
      },
      criacoes: {
        titulo: "Projects developed",
        paragrafo: "Here are some projects developed on web and mobile",
        botao: "Project Gallery",
      },
      aplicativo: {
        titulo: "Ideas that came to life",
      },
      error: {
        titulo: "Hello, you landed on the famous error page :P",
        paragrafo:
          "That means... there was an error! Either we couldn't load your page, or you entered the wrong address. It happens, right? So, please: type it again, or come back a little later.",
        botao: "Return",
      },
      servicos: {
        titulo: "Mídia Kit",
        paragrafo: "Services I can help and assist you with",
        servico1: "Lectures",
        servico2: "Disclosures on the Networks",
        servico3: "Events",
      },
    },
  },
};
export { StringsComuns }
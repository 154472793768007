export const lightTheme = {
  body: "#E6E5E5",
  button: "#996df9",
  title: "#121212",
  paragraph: "#121212",
  header: "#e9e3f7",
  cards: "#E9E3F7",
  cardsText: "#1c1a1a",
};

export const darkTheme = {
  body: "#121212",
  button: "#8147F8",
  title: "#FFFFFF",
  paragraph: "#C6C6C6",
  header: "#1c1a1a",
  cards: "#FFFFFF",
  cardsText: "#1c1a1a",
};

export const breakPoints = {
  small: "600px",
  minLarge: "601px",
  full: "1400px",
  average: "1250px",
  tablet: "1020px",
  mobile: "850px",
  smallMobile: "700px",
};

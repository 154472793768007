import styled from "styled-components";

export const Header = styled.div`
  .social-links1 {
    flex-direction: row;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .social-links1 li a {
    width: 70px;
    height: 70px;
    transition: transform 0.3s ease;
  }

  .fab {
    color: rgba(191,72,228,1);
    font-size: 26px;
  }

  .social-links1 li a:hover {
    transform: rotate(360deg);
  }

  section {
    max-width: 600px;
    margin: 32px auto;
  }

  h1 {
    font-size: 1.6rem !important;
    color: ${(props) => props.theme.title};
  }

  h2 {
    margin-left: 0.5rem;
    font-size: 1.2rem !important;
  }

  .rede {
    margin-top: 4rem;
  }

  article {
    display: flex;
    padding: 14px;
    margin: 1rem;
    border-radius: 14px;
    justify-content: center;
    /* color: azure; */
    /* background: #9367e0; */
    background: linear-gradient(90deg, rgba(191,72,228,1) 35%, #9367e0 100%);
  }

  article:hover {
    background: #9367e0;
    transition: 0.8s;
  }
  img {
    height: auto;
    display: block;
  }

  header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  h2,
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  header img {
    margin-top: 7rem;
    height: 190px;
    width: 190px;
    border-radius: 50%;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* header p {
    font-size: 1.3rem;
    background: linear-gradient(70deg, #b9a5d4 13%, #ac76ff 94%);
    font-weight: 400;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  } */
  section h2 {
    font-size: 1.4rem;
    font-weight: 400;
    color: ${(props) => props.theme.title};
  }
  section div a {
    flex-basis: 200px;
    flex-grow: 1;
  }
  @media screen and (max-width: 768px) {
    section div a {
      flex-basis: 600px;
    }
  }
`;

export const Pergunta = styled.summary`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-weight: 500;
  color: ${(props) => props.theme.title};
`;

export const Resposta = styled.details`
  padding: 1.5rem 0 0.5rem;
  text-align: left;
  line-height: 1.6;
  color: ${(props) => props.theme.paragraph};
  font-weight: 400;
  list-style: none;
`;

export const Lista = styled.li`
  list-style: none;
  padding: 1rem 0;
  margin: 1.3rem;
  border-bottom: 1px solid #8147f8;
  font-weight: 500;
  :nth-child(1) {
    border-top: 1px solid ${(props) => props.theme.body};
  }
`;
import styled from "styled-components";

export const Container = styled.div`
  padding-bottom: 4rem;
  padding-top: 4rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  a {
    display: inline-block;
    margin-top: 2rem;
  }
  img {
    height: 200px;
    width: 200px;
  }
`;

export const Informacao = styled.a`
  padding: 0.6rem 6rem;
  background: ${(props) => props.theme.button};
  border: 2px solid ${(props) => props.theme.button};
  border-radius: 0.3rem;
  box-shadow: 0 0 5px #8147f8;
  -webkit-box-shadow: 0 0 10px #8147f8;
  font-size: 0.9rem;
  font-weight: 500;
  color: ${(props) => props.theme.title};
  @media (max-width: 800px) {
    padding: 0.6rem 3rem;
  }
`;

export const Informacao2 = styled.a`
  width: 100%;
  margin-top: 1rem;
  text-align: center;
  padding: 0.6rem 4rem;
  background: ${(props) => props.theme.button};
  border: 2px solid ${(props) => props.theme.button};
  border-radius: 0.3rem;
  box-shadow: 0 0 5px #8147f8;
  -webkit-box-shadow: 0 0 10px #8147f8;
  font-size: 0.9rem;
  font-weight: 500;
  color: ${(props) => props.theme.title};
`;

export const Container2 = styled.div`
  display: flex;
  flex-direction: column;
`;
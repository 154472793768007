import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const ImagemProjeto = styled.img`
  border-radius: 0.3rem;
  width: 100%;
  height: 11rem;
`;

export const BotaoGit = styled.button`
  font-weight: bold;
`;

export const ContainerBtn = styled.div`
  display: flex;
  justify-content: center;
  button {
    background: ${(props) => props.theme.button};
    color: ${(props) => props.theme.title};
    box-shadow: 0 0 5px #8147f8;
    -webkit-box-shadow: 0 0 10px #8147f8;
    border-radius: 0.5rem;
    margin-top: 1rem;
    padding: 1rem 8rem;
  }
`;

export const CardText = styled.div`
  h2 {
    margin-top: 0.5rem;
    font-size: 1.5rem;
    text-align: center;
  }
`;

export const CardContainer = styled.div`
  box-sizing: border-box;
  display: grid;
  justify-content: center;
  display: flex;
`;

export const Card = styled.div`
  padding: 0.3rem;
  margin: 0.5rem;
  border-radius: 4px;
  outline: none;
  color: ${(props) => props.theme.title};
  border: 3px solid #8147f8;
  box-shadow: 0 0 5px #8147f8;
  -webkit-box-shadow: 0 0 10px #8147f8;
  border-radius: 1rem;
`;

export const Tag = styled.div`
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  h6 {
    font-size: 10px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 15px;
    font-weight: bold;
    color: #ffffff;
    background-color: #333;
    margin: 3px;
  }
`;

import styled from "styled-components";

export const ContainerGeral = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 7rem;
  @media (max-width: 550px) {
    margin-top: 0rem;
  }
`;

export const Centro = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 10px;
  display: inline-flex;
`;

export const Lado = styled.div`
  padding: 5rem;
`;

export const Coluna = styled.ul`
  width: 600px;
  overflow: hidden;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* position: center; */
  position: relative;
  @media (max-width: 550px) {
    width: 340px;
    /* height: 60px; */
    /* padding: 0.8rem; */
  }
  .slider {
  display: flex;
  flex-wrap: nowrap;
  /* justify-content: center; */
  
  animation: slide 15s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}


  img {
    /* margin: 1rem; */
    width: 130px;
    height: 130px;
    border-radius: 1rem;
    /* border: 3px solid #8147f8;
    box-shadow: 0 0 5px #8147f8;
    -webkit-box-shadow: 0 0 10px #8147f8; */
    @media (max-width: 500px) {
      width: 90px;
    }
  }
  h6 {
    font-size: 15px;
    color: ${(props) => props.theme.title};
  }
  @media (max-width: 800px) {
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
  }
`;
// aqui é os cards

export const Titulo = styled.h2`
  font-size: 1.4rem;
  text-align: center;
  margin-top: 1rem;
`;

export const Paragrafo = styled.p`
  max-width: 35rem;
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

export const Centro1 = styled.div`
  justify-content: center;
  margin: 0.7rem;
  padding: 0.7rem;
  text-align: center;
  width: 12rem;
  box-shadow: 0 0 5px #8147f8;
  -webkit-box-shadow: 0 0 10px #8147f8;
  border-radius: 0.7rem;
  background-color: ${(props) => props.theme.cards};
  @media (max-width: 550px) {
    width: 100%;
    height: 60px;
    /* padding: 0.8rem; */
  }
`;

export const Coluna1 = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  @media (max-width: 550px) {
    /* flex-direction: column; */
  }
  h6 {
    font-size: 15px;
  }
  @media (max-width: 800px) {
    align-items: center;
    justify-content: center;
    /* padding: 0.5rem; */
  }
`;

export const CardsRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4rem;
  @media (max-width: 550px) {
    flex-direction: column;
    margin-bottom: 2rem;
  }
`;
export const Demogr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 240px;
  color: ${(props) => props.theme.title};
  @media (max-width: 550px) {
    width: 100%;
    padding: 2rem;
  }
`;

export const ParagrafoCardParcerias = styled.p`
  font-size: 1rem;
  margin-top: 0.3rem;
  text-align: center;
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.title};
`;

export const ImageDemog = styled.img`
  margin: 4rem;
  width: 220px;
  height: 200px;
  justify-content: center;
`;

export const CardsStyled = styled.div`
  width: 220px;
  height: 190px;
  border-radius: 1rem;
  text-align: center;
  padding: 0.5rem;
  justify-content: center;
  margin: 1rem;
  /* background-color: ${(props) => props.theme.header}; */
  background-color: ${(props) => props.theme.cards};
  box-shadow: 0 0 5px #8147f8;
  -webkit-box-shadow: 0 0 10px #8147f8;
`;

